/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {fetchProductImage, fetchProductNew} from "./actions/actions";
import {GLN, GTIN, SSCC, TOKEN} from "./Constants";

import ProductGTINView from "./ProductGTINView";
import ProductGLNView from "./ProductGLNView";
import ProductSSCCView from "./ProductSSCCView";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
//import countriesData from './data/countries3.json';
import {getViewReducer} from "./reducers/productViewReducer"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;




const ProductOneView = ({
                            match: {params: { productId}}

}) => {

    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = getViewReducer(); //useReducer(reducer, initialState);
    const {product, loading} = state

    useEffect(() => {
        console.log('useEffect called');
        fetchProductNew(dispatch, token, productId);
    }, [productId]);


    return (
        <div className="inner-container">

            <div className="buttons has-addons is-right">
            <Link to={`/product/${productId}/edit`} className="button">
                Өөрчлөх
            </Link>
                <Link className="button" to={`/hq-product/${product.barcode}`}>hq</Link>
            </div>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            {(product.barcode_type === null || product.barcode_type === GTIN) &&
                <ProductGTINView p={product}/>
            }
            {product.barcode_type === GLN &&
                <ProductGLNView p={product}/>
            }
            {product.barcode_type === SSCC &&
                <ProductSSCCView p={product}/>
            }
            <hr/>

        </div>
    )
}

export default ProductOneView;

