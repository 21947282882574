import {Link} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import {fetchProductApplications, menuClicked} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import {ActionTypes, Menu, PageTitle, TOKEN} from './Constants';
import dayjs from "dayjs";
import history from "./history";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    requests:[],
    meta: {
        totalPages: 0,
        page: 0,
        size: 25
    },
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PRODUCT_APPLICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_PRODUCT_APPLICATIONS_SUCCESS:
            return {
                ...state,
                requests: action.requests,
                meta: action.meta,
                loading: false
            }
        default:
            return state;
    }
};


const ProductApplications = ({
                                 location: {search}
                             }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {requests} = state

    let parsed = qs.parse(search);
    let page = parsed["pg"];
    let pagesize = parsed["ps"];
    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }

    let token = localStorage.getItem(TOKEN) || null;

    useEffect(() => {
        dispatch(menuClicked(Menu.PROD_APPLS, PageTitle.PROD_APPLS));
    }, []);

    useEffect(() => {
        console.log('useEffect called');
        fetchProductApplications(dispatch,  page, pagesize);
    }, [page, pagesize]);

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed["pg"] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };


    const getStatus = (stat) => {
        if (stat === "SENT") {
            return "ИРСЭН";
        } else if (stat === "ACTIVATED") {
            return "ИДЭВХТЭЙ";
        }
    }

    let c = 0;
    const items = requests.map(
        (item) => {
            c++;
            let c1 = page * pagesize + c;
            return (
                <tr key={item.id}>
                    <td><Link to={`/prod-appl/${item.id}`}>
                        {c1}
                    </Link>
                    </td>
                    <td><Link to={`/prod-appl/${item.id}`}>{item.company_name}</Link></td>
                    <td>
                        {item.brand_name}
                    </td>
                    <td>
                        <Link to={`/prod-appl/${item.id}`}>
                            {item.name}
                        </Link>
                    </td>
                    <td>{item.packaging}  {item.packaging_unit}</td>
                    <td>{item.created && dayjs(item.created).format("MM/DD/YYYY")}</td>
                    <td>{getStatus(item.status)}</td>

                </tr>
            )
        });


    return (
        <div className="inner-container">
            <div className="flex justify-end">
            <Link classname="my-4" to={"/prod-image-appls"}>Ирсэн зурагнууд</Link>
            </div>
            <h5 style={{marginBottom: "10px"}}><strong>Нийт: {state.meta.totalElements}</strong></h5>
            <ClipLoader color="#ffffff" loading={state.loading} css={override} size={150}/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Компани</th>
                    <th>Бренд Нэр</th>
                    <th>Нэр</th>
                    <th>Савлалтын хэмжээ /нэгж, багц/</th>
                    <th>Илгээсэн огноо</th>
                    <th>Статус</th>

                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={state.meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>

        </div>
    )
}


export default ProductApplications;


